import { css } from 'styled-components'

import { loadTypekitSiteFonts } from '../utils/fonts'

import apercuRegularWoff from './apercu-regular.woff'
import apercuRegularWoff2 from './apercu-regular.woff2'

export const APERCU = 'Apercu'
export const TERMINA = 'termina'

window._font = `
  @font-face {
    font-family: APERCU;
    src: url(${apercuRegularWoff}), url(${apercuRegularWoff2});
    font-weight: 400;
    font-style: normal;
  }

`

const getTerminaStyles = () => {
  const [termina] = loadTypekitSiteFonts([TERMINA])
  return termina.styles
}

export default css`
  @font-face {
    font-family: APERCU;
    src: url(${apercuRegularWoff}), url(${apercuRegularWoff2});
    font-weight: 400;
    font-style: normal;
  }

  ${getTerminaStyles()}
`
